import Vue from 'vue'
import VueRouter from 'vue-router'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import {getCookie} from "@/utils/cookies";

Vue.use(Buefy);
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import ('../views/Home.vue'),
        meta: {
            title: 'MBFormation | Home',
            /*metaTags: [
                {name: 'description', content: '...'},
                {name: 'keywords', content: '...'},
                {name: 'author', content: 'Florine PRATLONG'}
            ],
            meta: [
                {property: 'og:title', content: "..."},
                {property: 'og:site_name', content: '...'},
                {property: 'og:type', content: 'website'},
            ]*/
        },
    },
    {
        path: '/results',
        name: 'Results',
        component: () => import ('../views/Results.vue'),
        meta: {
            title: 'MBFormation | Results',
        },
    },
    {
        path: '/funnel',
        name: 'FunnelOne',
        component: () => import ('../views/FunnelOne.vue'),
        meta: {
            title: 'MBFormation | Choisissez votre formation',
        },
    },
    {
        path: '/credentials',
        name: 'Credentials',
        component: () => import ('../views/Credentials.vue'),
        meta: {
            title: 'MBFormation | Log in',
        },
    },
    {
        path: '/update',
        name: 'Update',
        component: () => import ('../views/Update.vue'),
        meta: {
            title: 'MBFormation | Update',
        },
    },
];


const router = new VueRouter({
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    const publicURLs = ['Home', 'Credentials', 'Results', 'FunnelOne']
    document.title = to.meta.title;
    const token = getCookie('token');
    if (token == null && !publicURLs.includes(to.name)) {
        return next({ path: '/' });
    } else {
        return next();
    }
});

router.onError(error => {
    console.log(error)
})

export default router;