import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Buefy from 'buefy'
import Vuex from 'vuex'
import 'buefy/dist/buefy.css'

import NotificationMixin from "./mixins/NotificationsMixin";

Vue.mixin(NotificationMixin)

Vue.use(Vuex)
Vue.prototype.$store = store

Vue.use(Buefy, {
  defaultIconPack: 'fas',
  defaultContainerElement: '#content',
  // ...
})


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')


