export default {
    methods: {
        notificationError(text) {
            this.$buefy.notification.open({
                message: text,
                type: 'is-danger',
            })
        },
        notificationSuccess(text) {
            this.$buefy.notification.open({
                message: text,
                type: 'is-success',
                position: 'is-top',
                duration: 6000
            })

        }
    }
}